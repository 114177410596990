import React, { useState } from "react";
import {
  ShareModalContainer,
  ShareModalContent,
  ShareModalTitle,
  ShareModalButton,
  ShareModalButtonContainer,
  TopContainer,
  CloseModal,
  CopyUrl,
  CopyUrlContaier,
  CopyButton,
  ShareModalButtonImg,
  CopiedMessage,
  CopiedMessageMobile,
  CoppiedContainer,
  CopyButtonMobile,
} from "./styles";
import FacebookIcon from "../../images/memorials/facebook.svg";
import WhatsapIcon from "../../images/memorials/whatsapp.svg";
import xIcon from "../../images/memorials/twitter.svg";
import InstagramIcon from "../../images/memorials/instagram.svg";

const ShareModal = ({
  shareOnFacebook,
  shareOnWhatsApp,
  shareOnX,
  shareOnInstagram,
  closeModal,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const currentUrl = window.location.href;

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <ShareModalContainer>
      <ShareModalContent>
        <TopContainer>
          <ShareModalTitle>Share</ShareModalTitle>
          <CloseModal onClick={closeModal}>X</CloseModal>
        </TopContainer>
        <ShareModalButtonContainer>
          <ShareModalButton onClick={shareOnFacebook}>
            <ShareModalButtonImg src={FacebookIcon} alt="facebook" />
          </ShareModalButton>
          <ShareModalButton onClick={shareOnInstagram}>
            <ShareModalButtonImg src={InstagramIcon} alt="instagram" />
          </ShareModalButton>
          <ShareModalButton onClick={shareOnX}>
            <ShareModalButtonImg src={xIcon} alt="x" />
          </ShareModalButton>
          <ShareModalButton onClick={shareOnWhatsApp}>
            <ShareModalButtonImg src={WhatsapIcon} alt="whatsapp" />
          </ShareModalButton>
          {isCopied && <CopiedMessage>Copied link</CopiedMessage>}
        </ShareModalButtonContainer>
        <CopyUrlContaier>
          <CopyUrl>{currentUrl}</CopyUrl>
          <CopyButton onClick={copyToClipboard}>Copy</CopyButton>
          <CopyButtonMobile onClick={copyToClipboard}>
            {!isCopied ? "Copy" : "Copied Link"}
          </CopyButtonMobile>
        </CopyUrlContaier>
      </ShareModalContent>
    </ShareModalContainer>
  );
};

export default ShareModal;
