import styled from "styled-components";
import { TABLET_740, MOBILE_460 } from "../../styles/globals/sizes";

export const MainContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000093 0% 0% no-repeat padding-box;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;

export const ModalContent = styled.div`
  width: 608px;
  height: auto;
  background-color: #404040;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (max-width: ${TABLET_740}px) {
    width: 90%;
    padding-inline-start: 20px;
  }

  @media (max-width: ${MOBILE_460}px) {
    width: 95%;
    padding-inline-start: 10px;
  }
`;

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CloseModal = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 20px;
  padding-bottom: 35px;
  margin-left: auto;
  &:hover {
    color: #eb4849;
  }
  @media (max-width: ${MOBILE_460}px) {
    font-size: 18px;
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  margin-bottom: 20px;
  padding-top: 10px;
  color: #ffffff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 10px;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 75%;
    border-bottom: 4px solid #ffffff;
  }

  @media (max-width: ${MOBILE_460}px) {
    font-size: 18px;
  }
`;

export const CheckContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;
  flex-wrap: wrap;

  p {
    font: normal normal normal 18px/28px Roboto;
    font-size: 18px;
    font-weight: normal;
    color: #ffffff;
    margin-right: 8px;

    @media (max-width: ${MOBILE_460}px) {
      font-size: 16px;
      text-align: left;
    }
  }
  @media (max-width: ${MOBILE_460}px) {
    margin-top: 0;
  }
`;

export const CheckboxLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  margin-left: 4px;
  font: normal normal normal 18px/28px Roboto;
  font-size: 18px;
  font-weight: normal;
  color: #ffffff;
  position: relative;
  cursor: pointer;
  user-select: none;

  @media (max-width: ${MOBILE_460}px) {
    font-size: 16px;
  }
`;

export const CheckBox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  & + span {
    position: relative;
    height: 20px;
    width: 20px;
    background-color: #404040;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #ffffff;
    margin-right: 4px;

    @media (max-width: ${MOBILE_460}px) {
      height: 18px;
      width: 18px;
    }
  }

  &:checked + span::after {
    content: "";
    position: absolute;
    left: 6px;
    top: 3px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);

    @media (max-width: ${MOBILE_460}px) {
      left: 5px;
      top: 2px;
      width: 3px;
      height: 7px;
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  background: #ffffff5f 0% 0% no-repeat padding-box;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  font-family: Roboto;

  ::placeholder {
    color: #ffffff;
    font-size: 14px;
  }

  @media (max-width: ${TABLET_740}px) {
    width: 100%;
    margin-top: 10px;
  }

  @media (max-width: ${MOBILE_460}px) {
    font-size: 16px;
    ::placeholder {
      font-size: 12px;
    }
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 125px;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  background: #ffffff5f 0% 0% no-repeat padding-box;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  font-family: Roboto;
  resize: none;
  overflow-y: auto;

  ::placeholder {
    color: #ffffff;
    font-size: 14px;
  }

  @media (max-width: ${TABLET_740}px) {
    width: 100%;
  }

  @media (max-width: ${MOBILE_460}px) {
    font-size: 16px;
    height: 100px;
    ::placeholder {
      font-size: 12px;
    }
  }
`;

export const PictureHandler = styled.div`
  width: 550px;
  padding: 10px;
  margin-top: 10px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  font-family: Roboto;
  cursor: pointer;

  label.custom-file-upload {
    display: inline-block;
    cursor: pointer;
  }

  @media (max-width: ${TABLET_740}px) {
    width: 100%;
  }

  @media (max-width: ${MOBILE_460}px) {
    font-size: 16px;
  }
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const SubmitButton = styled.button`
  width: 205px;
  height: 37px;
  padding: 10px;
  margin-top: 10px;
  padding-bottom: 20px;
  background-color: white;
  border: none;
  border-radius: 30px;
  color: #464748;
  font-size: 18px;
  font-weight: 400;
  font-family: Roboto;
  cursor: pointer;

  @media (max-width: ${MOBILE_460}px) {
    width: 100%;
    font-size: 16px;
  }
`;

export const SuccessMessage = styled.div`
  width: 100%;
  color: #ffffff;
  font-size: 30px;
  font-weight: bold;
  font-family: Montserrat;
  text-align: center;
  margin-bottom: 30px;

  @media (max-width: ${MOBILE_460}px) {
    font-size: 18px;
  }
`;
