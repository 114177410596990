import styled from "styled-components";
import {
  DESKTOP_1024,
  DESKTOP_1200,
  MOBILE_460,
  TABLET_600,
  TABLET_992,
} from "../globals/sizes";

export const MainContainer = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 130px;
  padding-bottom: 49px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: ${TABLET_600}px) {
    padding-top: 78px;
  }
`;

export const ImageTextContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: self-start;
  @media (max-width: ${TABLET_992}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ImageContainer = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: ${TABLET_600}px) {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`;

export const ButtonMobileContainer = styled.div`
  display: none;
  @media (max-width: ${TABLET_992}px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
`;

export const Button = styled.button`
  width: 201px;
  height: 48px;
  background: #e0281a;
  border-radius: 23px;
  font-family: Roboto;
  font-size: 16px;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 14px;
  margin-right: ${(props) => props.marginRight || "20px"};
  padding: 15px;
  &:hover {
    font-weight: bold;
  }
  @media (max-width: ${TABLET_600}px) {
    width: 100%;
    font-size: 19px;
  }
`;

export const CardImage = styled.div`
  width: 100%;
  height: 524px;
  object-fit: cover;
  z-index: 1;

  img {
    border-radius: 32px;
  }

  @media (max-width: ${TABLET_600}px) {
    max-width: 100vw;
    width: 100vw;

    img {
      border-radius: 0 !important;
    }
  }
`;
export const TextContainer = styled.div`
  width: 100%;
  max-width: 620px;
  padding-left: 40px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: ${DESKTOP_1200}px) {
    max-width: 560px;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    max-width: 450px;
    padding-top: 0px;
  }
  @media (max-width: ${TABLET_992}px) {
    max-width: 90%;
    padding-left: 0;
    padding-top: 30px;
  }
`;

export const FullName = styled.p`
  width: 50%;
  font-size: 40px;
  font-weight: normal;
  font-family: Roboto;
  letter-spacing: 0px;
  color: #e01a73;
  text-transform: uppercase;
  @media (max-width: ${TABLET_992}px) {
    width: 50%;
    margin-top: 0;
    font: normal normal normal 47px/54px Roboto;
  }
  @media (max-width: ${MOBILE_460}px) {
    width: 90%;
    font: normal normal normal 40px/40px Roboto;
  }
`;

export const LastName = styled(FullName)`
  @media (max-width: ${TABLET_992}px) {
    width: 100%;
    margin-top: 0px;
    font: normal normal normal 47px/54px Roboto;
  }
  @media (max-width: ${MOBILE_460}px) {
    width: 90%;
    font: normal normal normal 40px/40px Roboto;
  }
`;

export const DeathDate = styled.p`
  padding-top: 18px;
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  color: #404040;
  @media (max-width: ${TABLET_992}px) {
    padding-left: 0px;
  }
`;

export const Description = styled.div`
  font: normal normal normal 22px/29px Roboto;
  letter-spacing: 0px;
  color: #404040;
  width: 100%;
  p {
    padding-top: 22px;
    color: #404040;
  }

  @media (max-width: ${TABLET_992}px) {
    width: 90%;
  }
`;
