import React, { useState } from "react";
import {
  MainContainer,
  ModalContent,
  Title,
  TopContainer,
  CloseModal,
  CheckContainer,
  CheckboxLabel,
  CheckBox,
  Input,
  Textarea,
  PictureHandler,
  SubmitButton,
  HiddenFileInput,
  SuccessMessage,
} from "./styles";
import {
  createMemorialMessage,
  uploadImageToStrapi,
} from "../../services/integrations";

const options = [
  { label: "Relative", value: "Relative" },
  { label: "Friend", value: "Friend" },
  { label: "Other", value: "Other" },
];

const LeaveAMessageModal = ({ closeModal, memorialPersonId }) => {
  const [selectedOption, setSelectedOption] = useState("relative");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [fullName, setFullName] = useState("");
  const [message, setMessage] = useState("");
  const [otherOption, setOtherOption] = useState("");
  const [fileName, setFileName] = useState([]);
  const [files, setFiles] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const newFileNames = newFiles.map((file) => file.name);
    setFileName((prevFileNames) => [...prevFileNames, ...newFileNames]);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    const data = {
      fullName,
      message,
      memorialPersonId,
      relationship: selectedOption,
    };
    if (selectedOption === "Other") {
      data.relationship = otherOption;
    }
    const responseCMS = await createMemorialMessage(data, files);
    if (responseCMS) {
      setShowSuccess(true);
      setLoadingSubmit(false);
    }
  };

  return (
    <MainContainer>
      <ModalContent>
        <TopContainer>
          {!showSuccess && <Title>Leave a message</Title>}
          <CloseModal onClick={closeModal}>X</CloseModal>
        </TopContainer>
        {loadingSubmit ? (
          <SuccessMessage>Loading...</SuccessMessage>
        ) : (
          showSuccess && (
            <SuccessMessage>Thank you! Your message was sent.</SuccessMessage>
          )
        )}
        {!loadingSubmit && !showSuccess && (
          <>
            <CheckContainer>
              <p>Relationship:</p>
              {options.map((option) => (
                <CheckboxLabel key={option.value}>
                  <CheckBox
                    type="radio"
                    name="relationship"
                    value={option.value}
                    checked={selectedOption === option.value}
                    onChange={handleOptionChange}
                  />
                  <span></span>
                  <p>{option.label}</p>
                </CheckboxLabel>
              ))}
            </CheckContainer>
            {selectedOption === "Other" && (
              <Input
                type="text"
                placeholder="Other"
                onChange={(event) => setOtherOption(event.target.value)}
              />
            )}
            <Input
              type="text"
              placeholder="Full Name"
              onChange={(event) => {
                setFullName(event.target.value);
              }}
            />
            <Textarea
              placeholder="Message"
              onChange={(event) => setMessage(event.target.value)}
            />
            <PictureHandler>
              <label htmlFor="file-upload" className="custom-file-upload">
                + Add a Picture
              </label>
              <HiddenFileInput
                id="file-upload"
                type="file"
                multiple
                onChange={handleFileChange}
              />
              {fileName &&
                fileName.map((name, index) => (
                  <p style={{ marginBlock: 5 }} key={index}>
                    {name}
                  </p>
                ))}
            </PictureHandler>
            <SubmitButton onClick={handleSubmit}>Send</SubmitButton>
          </>
        )}
      </ModalContent>
    </MainContainer>
  );
};

export default LeaveAMessageModal;
