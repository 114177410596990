import styled from "styled-components";
import {
  DESKTOP_1024,
  DESKTOP_1200,
  TABLET_600,
  TABLET_992,
} from "../../styles/globals/sizes";

export const CommentContainer = styled.div`
  width: 100%;
  max-width: 1130px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: ${DESKTOP_1200}px) {
    max-width: 1037px;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    max-width: 910px;
  }
  @media (max-width: ${TABLET_992}px) {
    padding-inline: 0px;
    width: 90%;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  background: #aaaaaa;
  opacity: 0.2;
  margin-bottom: 30px;
`;

export const TextDescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  @media (max-width: ${TABLET_992}px) {
    min-width: 100%;
    flex-direction: column;
    justify-content: center;
  }
`;
export const LeftTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 220px;
  @media (max-width: ${TABLET_600}px) {
    margin-bottom: 30px;
  }
`;

export const CommentName = styled.p`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  color: #ec4747;
`;

export const Relationship = styled.p`
  padding-top: 10px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  color: #4b4b4b;
`;

export const CommentDate = styled.p`
  padding-top: 10px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  color: #4b4b4b;
`;

export const CommentText = styled.p`
  width: 100%;
  max-width: 909px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  color: #404040;
  line-height: 1.5;
  @media (max-width: ${DESKTOP_1200}px) {
    max-width: 750px;
  }
`;

export const CommentImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImagesRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  :hover {
    cursor: pointer;
  }
`;

export const FamilyImageContainer = styled.div`
  width: 164px;
  height: 168px;
  margin-block: 10px;
  img {
    max-width: 100%;
    border-radius: 5px;
  }
  @media (max-width: ${TABLET_600}px) {
    width: auto;
    height: auto;
  }
`;
