import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "../components/layout";
import Seoinfo from "../components/seoinfo/seoinfo";
import Markdown from "../components/Common/Markdown";
import MediaRender from "../components/Common/MediaRender";
import {
  ButtonContainer,
  CardImage,
  DeathDate,
  Description,
  FullName,
  ImageContainer,
  ImageTextContainer,
  MainContainer,
  TextContainer,
  Button,
  ButtonMobileContainer,
  LastName,
} from "../styles/templates/bio";
import ShareIcon from "../images/memorials/share-icon.svg";
import ShareModal from "../components/ShareModal";
import LeaveAMessageModal from "../components/LeaveAMessageModal";
import MessageMemorial from "../components/MessageMemorial";
import { useLocation } from "@reach/router";

const Bio = ({ data }) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const dataCMS = data?.cms?.memorial?.data?.attributes;
  const [isLeaveAMessageOpen, setIsLeaveAMessageOpen] = useState(false);
  const location = useLocation();

  const formatDate = (dateString) => {
    const [year, day, month] = dateString.split("-");
    const fullYear = year.length === 2 ? `20${year}` : year;
    return `${month}/${day}/${fullYear}`;
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("openMessage") === "true") {
      setIsLeaveAMessageOpen(true);
    }
  }, [location.search]);

  const shareOnFacebook = () => {
    const currentUrl = window.location.href;
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      currentUrl
    )}`;
    window.open(facebookShareUrl, "_blank");
  };

  const shareOnWhatsApp = () => {
    const currentUrl = window.location.href;
    const message = `Check this out: ${currentUrl}`;
    const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappShareUrl, "_blank");
  };

  const shareOnX = () => {
    const currentUrl = window.location.href;
    const xShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      currentUrl
    )}`;
    window.open(xShareUrl, "_blank");
  };

  const shareOnInstagram = () => {
    const instagramUrl = "https://www.instagram.com";
    window.open(instagramUrl, "_blank");
  };

  return (
    <HelmetProvider>
      <Layout>
        <Helmet>
          <link rel="canonical" href="https://hospicecare.life/" />
        </Helmet>
        <Seoinfo
          siteName={
            "End-of-Life Care: Providing Comfort and Dignity  | Life Hospice"
          }
          titlePage={
            "End-of-Life Care: Providing Comfort and Dignity  | Life Hospice"
          }
          description={
            "Our mission: Compassionate and High-Quality End-of-Life Care. Meeting Medical, Spiritual, and Emotional Needs with Care and Respect."
          }
          keywords={
            "end-of-life care, comfort and dignity, patient-centered care, compassionate care, quality care"
          }
          type={"website"}
          url={"hospice.life"}
        />
        <MainContainer>
          <ImageTextContainer>
            <ImageContainer>
              <CardImage>
                <MediaRender mediaSource={dataCMS.Image} />
              </CardImage>
              <ButtonContainer>
                <Button onClick={() => setIsLeaveAMessageOpen(true)}>
                  LEAVE A MESSAGE
                </Button>
                <Button
                  paddingRight="20px"
                  onClick={() => setIsShareModalOpen(true)}
                >
                  <img
                    style={{ marginRight: 10, height: 26 }}
                    src={ShareIcon}
                    alt="share"
                  />
                  SHARE
                </Button>
              </ButtonContainer>
            </ImageContainer>
            <TextContainer>
              <FullName>{dataCMS.Name}</FullName>
              <LastName>{dataCMS.Last_Name}</LastName>
              <DeathDate>
                {formatDate(dataCMS.Birthdate)} -{" "}
                {formatDate(dataCMS.Date_Of_Death)}
              </DeathDate>
              <Description>
                <Markdown text={dataCMS.Bio} />
              </Description>
              <ButtonMobileContainer>
                <Button onClick={() => setIsLeaveAMessageOpen(true)}>
                  LEAVE A MESSAGE
                </Button>
                <Button onClick={() => setIsShareModalOpen(true)}>
                  <img
                    style={{ marginRight: 10, height: 22 }}
                    src={ShareIcon}
                    alt="share"
                  />
                  SHARE
                </Button>
              </ButtonMobileContainer>
            </TextContainer>
          </ImageTextContainer>
          <MessageMemorial memorialId={data?.cms?.memorial?.data?.id} />
        </MainContainer>
        {isShareModalOpen && (
          <ShareModal
            shareOnFacebook={shareOnFacebook}
            shareOnWhatsApp={shareOnWhatsApp}
            shareOnX={shareOnX}
            shareOnInstagram={shareOnInstagram}
            closeModal={() => setIsShareModalOpen(false)}
          />
        )}
        {isLeaveAMessageOpen && (
          <LeaveAMessageModal
            memorialPersonId={data?.cms?.memorial?.data?.id}
            closeModal={() => setIsLeaveAMessageOpen(false)}
          />
        )}
      </Layout>
    </HelmetProvider>
  );
};

export const pageQuery = graphql`
  query($id: ID!) {
    cms {
      memorial(id: $id) {
        data {
          id
          attributes {
            Name
            Last_Name
            Birthdate
            Date_Of_Death
            Bio
            Image {
              ...Media
            }
          }
        }
      }
    }
  }
`;

export default Bio;
