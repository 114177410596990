import React, { useState, useEffect } from "react";
import ImageCarousel from "../ImageCarousel";
import {
  Separator,
  CommentText,
  CommentDate,
  Relationship,
  CommentName,
  CommentContainer,
  LeftTextContainer,
  TextDescriptionContainer,
  CommentImageContainer,
  FamilyImageContainer,
  ImagesRowContainer,
} from "./styles";
import { getMemorialMessage } from "../../services/integrations";
import MediaRender from "../Common/MediaRender";

const MessageMemorial = ({ memorialId }) => {
  const [isCarouselOpen, setIsCarouselOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [carouselImages, setCarouselImages] = useState([]);
  const [comments, setComments] = useState([]);
  const handleComments = async () => {
    const allComments = await getMemorialMessage(memorialId);
    setComments(allComments);
  };

  const handleImageClick = (images, index) => {
    setCarouselImages(images);
    setCurrentImageIndex(index);
    setIsCarouselOpen(true);
  };

  const handleCloseCarousel = () => {
    setIsCarouselOpen(false);
  };

  const handleDate = (dateString) => {
    const date = new Date(dateString);

    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(date);
  };

  useEffect(() => {
    handleComments();
  }, []);

  return (
    <>
      {comments &&
        comments.map((card) => (
          <CommentContainer key={card.id}>
            <Separator />
            <TextDescriptionContainer>
              <LeftTextContainer>
                <CommentName>{card.Full_Name}</CommentName>
                <Relationship>{card.Relationship}</Relationship>
                <CommentDate>{handleDate(card.createdAt)}</CommentDate>
              </LeftTextContainer>
              <CommentImageContainer
                className={card.Images && card.Images.length === 2 ? "row" : ""}
              >
                <CommentText>{card.Message}</CommentText>
                {card.Images && (
                  <ImagesRowContainer>
                    {card.Images.map((img, imgIndex) => (
                      <FamilyImageContainer
                        key={imgIndex}
                        onClick={() => handleImageClick(card.Images, imgIndex)}
                      >
                        <MediaRender mediaSource={img} />
                      </FamilyImageContainer>
                    ))}
                  </ImagesRowContainer>
                )}
              </CommentImageContainer>
            </TextDescriptionContainer>
          </CommentContainer>
        ))}
      {isCarouselOpen && (
        <ImageCarousel
          images={carouselImages}
          currentIndex={currentImageIndex}
          onClose={handleCloseCarousel}
        />
      )}
    </>
  );
};

export default MessageMemorial;
